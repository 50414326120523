/*
 * Copyright 2020 Adobe. All rights reserved.
 * This file is licensed to you under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License. You may obtain a copy
 * of the License at http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software distributed under
 * the License is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES OR REPRESENTATIONS
 * OF ANY KIND, either express or implied. See the License for the specific language
 * governing permissions and limitations under the License.
 */
/**
 * Variables for interacting with AEM components.
 * @private
 */
export class Constants {
    constructor() {
        // hide constructor
    }
}
/**
 * Type of the item.
 */
Constants.TYPE_PROP = ':type';
/**
 * List of child items of an item.
 */
Constants.ITEMS_PROP = ':items';
/**
 * Order in which the items should be listed.
 */
Constants.ITEMS_ORDER_PROP = ':itemsOrder';
/**
 * Path of an item.
 */
Constants.PATH_PROP = ':path';
/**
 * Children of a hierarchical item.
 */
Constants.CHILDREN_PROP = ':children';
/**
 * Hierarchical type of the item.
 */
Constants.HIERARCHY_TYPE_PROP = ':hierarchyType';
/**
 * JCR content node.
 */
Constants.JCR_CONTENT = 'jcr:content';
/**
 * AEM modes.
 */
export var AEM_MODE;
(function (AEM_MODE) {
    AEM_MODE["EDIT"] = "edit";
    AEM_MODE["PREVIEW"] = "preview";
    AEM_MODE["DISABLED"] = "disabled";
})(AEM_MODE || (AEM_MODE = {}));
/**
 * Supported tag types.
 * @private
 */
export var TAG_TYPE;
(function (TAG_TYPE) {
    TAG_TYPE["JS"] = "script";
    TAG_TYPE["STYLESHEET"] = "stylesheet";
})(TAG_TYPE || (TAG_TYPE = {}));
export default Constants;
