/*
 * Copyright 2020 Adobe. All rights reserved.
 * This file is licensed to you under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License. You may obtain a copy
 * of the License at http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software distributed under
 * the License is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES OR REPRESENTATIONS
 * OF ANY KIND, either express or implied. See the License for the specific language
 * governing permissions and limitations under the License.
 */
/**
 * Meta property names associated with the PageModelProvider and ModelRouter.
 * @private
 */
export class MetaProperty {
    constructor() {
        // hide constructor
    }
}
/**
 * Meta property pointing to page model root.
 */
MetaProperty.PAGE_MODEL_ROOT_URL = 'cq:pagemodel_root_url';
/**
 * Meta property pointing to route filters.
 */
MetaProperty.PAGE_MODEL_ROUTE_FILTERS = 'cq:pagemodel_route_filters';
/**
 * Meta property pointing to model router.
 */
MetaProperty.PAGE_MODEL_ROUTER = 'cq:pagemodel_router';
/**
 * Meta property pointing to wcm mode.
 */
MetaProperty.WCM_MODE = 'cq:wcmmode';
/**
 * Meta property with a editor data type hint
 */
MetaProperty.WCM_DATA_TYPE = 'cq:datatype';
export default MetaProperty;
